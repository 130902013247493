<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="param"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'hazop-plan',
  props: {
    param: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',  // 평가항목 개정번호
        count: 0,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  computed: {
    tabItems() {
      return [
        { key: uid(), name: 'planInfo', icon: 'edit', label: '평가점수 환산표', component: () => import(`${'./auditResultTable.vue'}`) },
        { key: uid(), name: 'team', icon: 'checklist', label: '평가항목별 감사결과', component: () => import(`${'./resultReport.vue'}`)},
      ]
    },
  },
  data() {
    return {
      editable: true,
      splitter: 5,
      tab: 'planInfo',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
  }
};
</script>
